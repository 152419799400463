import React from "react";
import NavBar from "../NavBar";
import styled from "@emotion/styled";

const Layout = (props) => {
    const { components: Component } = props;

    return (
        <div>
            <NavBar />
            <Container>
                <Component />
            </Container>
        </div>
    );
};

export default Layout;

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
`;
