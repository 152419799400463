import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import FormComponent from "../components/FormComponent";
import { getEventById } from "../api/events";
import { useParams } from "react-router-dom";

const Main = () => {
    const [event, setEvent] = useState(null);
    const [loadingEvent, setLoadingEvent] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        event === null && handleEvent();
        // eslint-disable-next-line
    }, [event]);

    const handleEvent = async () => {
        setLoadingEvent(true);
        try {
            const res = await getEventById(id);

            setEvent(res);
            setLoadingEvent(false);
        } catch (error) {
            setEvent(false);
            setLoadingEvent(false);
        }
    };

    return (
        <Container>
            {loadingEvent ? (
                <p>Cargando información del evento...</p>
            ) : (
                <>
                    <Header>¡Marca tu asistencia!</Header>
                    <Body>
                        <p>EVENTO</p>
                        <h3>
                            {event?.name
                                ? event?.name
                                : "EVENTO NO IDENTIFICADO"}
                        </h3>
                        <FormComponent id={id} event={event} />
                    </Body>
                </>
            )}
        </Container>
    );
};

export default Main;

const Container = styled.div`
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;

    @media (max-width: 550px) {
        width: 90%;
        padding: 0 1rem;
    }
`;

const Header = styled.div`
    width: 100%;
    background-color: #b31d15;
    color: #fff;
    font-size: 1.3rem;
    padding: 2px;
    font-weight: bold;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "PT Serif", serif;
    font-style: italic;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;

    p {
        color: #535353;
        font-size: 1.3rem;
    }

    h3 {
        color: #1d1d1b;
        font-size: 1.7rem;
        text-align: center;
    }
`;
