import React from "react";
import { ErrorMessage, Field } from "formik";
import { Autocomplete, TextField } from "@mui/material"; // import { TextField } from '@mui/core';
import styled from "@emotion/styled";

const DefaultComplete = (props) => {
    const { name, label, value, setFieldValue, options = [] } = props;

    // RETURN
    return (
        <FieldFake
            as={Autocomplete}
            name={name}
            value={value?.code}
            onChange={(e, newValue) => {
                if (newValue !== null) {
                    setFieldValue(name, newValue);
                }
            }}
            options={options}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
                <TextFieldFake
                    {...params}
                    label={label}
                    variant="outlined"
                    helperText={
                        <ErrorMessage name={name} component={CustomError} />
                    }
                />
            )}
            {...props}
        />
    );
};

export default DefaultComplete;

const CustomError = styled.span`
    font-size: 0.75rem;
    font-weight: 700;
    color: #f8423f;
`;

const FieldFake = styled(Field)`
    /* border: solid 1px #b31d15;
    width: 100%;
    text-align: center;
    color: #b31d15;
    font-weight: bold;
    height: 3rem;
    border-radius: 10px;
    outline: none; */

    ::placeholder {
        color: #b31d15;
    }
`;

const TextFieldFake = styled(TextField)`
    border: none;

    label {
        color: #b31d15;
        font-size: 0.8rem;
        font-weight: bold;
    }
    input {
        color: #b31d15;
    }

    fieldset {
        border: solid 1px #b31d15;
        border-radius: 10px;

        color: #b31d15;
    }
`;
