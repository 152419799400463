import axios from "axios";

export const getEventById = async (id) => {
    try {
        const req = await axios.get(
            `https://sisapi.aden.org/api/eventos/${id}`
        );
        return req.data;
    } catch (error) {
        throw new Error(error);
    }
};


export const postAttended = async (eventId, email) => {
    try {
        const req = await axios.get(
            `https://sisapi.aden.org/api/v1/acropolis/events/attended_email/${eventId}/${email}`
        );
        return req.data.response_data;
    } catch (error) {
        throw new Error(error);
    }
};

export const postAttendedRegister = async (body) => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: "3add9e84-c96a-497a-ab22-f6b742472e2e",
    };
    try {
        const req = await axios.post(
            `https://sisapi.aden.org/api/v2/acropolis/events/register-free`,
            body,
            { headers: headers }
        );
        return req.data;
    } catch (error) {
        throw new Error(error);
    }
};

export const getCountries = async () => {
    try {
        const req = await axios.get(
            `https://sisapi.aden.org/api/v1/acropolis/miscs/countries`
        );
        return req.data.response_data;
    } catch (error) {
        throw new Error(error);
    }
};
