import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function MaterialThemeProvider(props) {
    const theme = createTheme({
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: 25,
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingLeft: 20,
                        paddingRight: 20,
                    },
                },
            },
        },
        typography: {
            fontSize: 14,
            button: {
                textTransform: "none",
                fontWeight: 600,
            },
        },
        palette: {
            primary: {
                main: "#333",
                contrastText: "#fff",
            },
            background: {
                default: "#f9f9f9 !important",
            },
            secondary: {
                main: "#FFFFFF",
                contrastText: "#333",
            },
            muted: {
                main: "#c4c4c4",
            },
            error: {
                main: "#f8423f",
            },
        },
        props: {
            MuiButtonBase: {
                disableRipple: false,
            },
            MuiPopover: {
                elevation: 1,
            },
        },
    });

    const { children } = props;

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
}