import { createBrowserRouter } from "react-router-dom";
import Layout from "../components/layout/Layout";
import Main from "../pages/Main";
// import Error from "../pages/Error";
// import ErrorImage from "../assets/404-error.svg";

export const router = createBrowserRouter([
    {
        path: `/evento/:id`,
        element: <Layout components={Main} />,
    },
]);
