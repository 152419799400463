import React from "react";
import { ErrorMessage, Field } from "formik";
import styled from "@emotion/styled";

const DefaultField = (props) => {
    const { name, type, label } = props;

    // RETURN
    return (
        <Container>
            <FieldFake
                name={name}
                type={type}
                label={label}
                variant="outlined"
                color="primary"
                placeholder={label}
                {...props}
            />
            <ErrorMessageCustom name={name} component={CustomError} />
        </Container>
    );
};

export default DefaultField;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
`;

const CustomError = styled.span`
    font-size: 0.89rem;
    font-weight: 700;
    color: #f8423f;
`;

const FieldFake = styled(Field)`
    border: solid 1px #b31d15;
    width: 100%;
    text-align: center;
    color: #b31d15;
    font-weight: bold;
    height: 3rem;
    border-radius: 10px;
    outline: none;

    ::placeholder {
        color: #b31d15;
    }
`;

const ErrorMessageCustom = styled(ErrorMessage)`
    position: absolute;
    left: 10px;
    top: 3px;

    width: fit-content;
    overflow: visible;
`;
