import * as yup from "yup";

export const existingSchema = yup.object().shape({
    email: yup
        .string()
        .nullable()
        .email("Formato incorrecto")
        .required("Correo requerido")
        .max(320, "Máximo 320 caracteres"),
});

export const notExistingSchema = yup.object().shape({
    email: yup
        .string()
        .nullable()
        .email("Formato incorrecto")
        .required("Correo requerido")
        .max(320, "Máximo 320 caracteres"),

    name: yup.string().nullable().required("Nombre requerido"),

    lastName: yup.string().nullable().required("Apellido requerido"),

    country_id: yup.object().nullable().required("País requerido"),

    phone: yup
        .number("Debe ser un número válido")
        .positive("Teléfono invalido")
        .required("Teléfono requerido"),
});
