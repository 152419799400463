import React from "react";

import { ErrorMessage, Field } from "formik";
import PhoneInput from "react-phone-input-2";
import styled from "@emotion/styled";
import "react-phone-input-2/lib/material.css";

const InputPhone = (props) => {
    const {
        name,
        label,
        value,
        setFieldValue,
        styled = { width: "100%", borderColor: "inherit" },
    } = props;

    // RETURN
    return (
        <>
            <Field
                as={PhoneInputFake}
                name={name}
                value={value}
                onChange={(newValue) => {
                    setFieldValue(name, newValue);
                }}
                country={"ar"}
                countryCodeEditable={true}
                enableSearch={true}
                autoFormat={true}
                specialLabel={label}
                inputStyle={styled}
                {...props}
            />
            <ErrorMessageFake name={name} component={CustomError} />
        </>
    );
};

export default InputPhone;

const ErrorMessageFake = styled(ErrorMessage)`
    margin-top: -1.5rem;
    padding-left: 1rem;
`;

const CustomError = styled.span`
    font-size: 0.75rem;
    font-weight: 700;
    color: #b31d15;
`;

const PhoneInputFake = styled(PhoneInput)`
    /* background-color: red; */
    margin-bottom: 4px;
    color: #b31d15;
    input {
        height: 56px;
        padding: 11px 4px 11px 52px !important;
        font-size: 14px !important;
        border-radius: 10px !important;
    }
    .special-label {
        font-size: 10px !important;
        left: 12px !important;
        color: #b31d15;
    }
    border-color: #b31d15;
`;
