import styled from "@emotion/styled";
import React from "react";
import Logo from "../assets/logo-aden.png";

const NavBar = () => {
    return (
        <Container>
            <img src={Logo} alt="Logo ADEN" />
        </Container>
    );
};

export default NavBar;

const Container = styled.div`
    width: 100%;
    background-color: #b31d15;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 300px;
    }
`;
